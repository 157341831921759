.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}
.container {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  padding: 1rem 2rem;
}
.container h2 {
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.container p {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 4rem;
}
.banner__wrapper {
  display: flex;
  margin-top: 3rem;
  padding: 2px;
  width: 80%;
  border-radius: 10px;
  overflow: hidden;
  background: linear-gradient(268.94deg, #5079e6 0.83%, #784edc 104.05%);
}
.hundreds__more {
  font-size: 2rem;
  line-height: 2.8rem;
  margin: 4rem 0 2rem 0;
  text-decoration: none;
  color: var(--text-color);
  transition: 0.2s ease;
}
.hundreds__more:hover {
  text-decoration: none;
  transform: scale(1.05, 1.05);
}

.banner {
  background-color: var(--raised-background-color);
  display: flex;
  padding: 2rem;
  width: 100%;
  border-radius: 10px;
  align-items: center;
}
.banner img {
  height: 3rem;
  width: auto;
  flex-shrink: 0;
}
.button {
  flex-shrink: 0;
}
.banner__text {
  padding: 0 2rem;
  flex-shrink: 999;
}

.grid__wrapper {
  width: 100%;
}
.grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.grid_item__wrapper {
  padding: 0.5rem 1rem;
  flex: 0 1 50%;
  display: flex;
}
.grid_item__wrapper__disabled {
  pointer-events: none;
}
.grid_item {
  background-color: var(--raised-background-color);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  flex: 1;
  border: 1px solid var(--background-color);
  text-decoration: none;
  color: var(--text-color);
  transition: 0.2s ease;
  overflow: hidden;
  position: relative;
}
.grid_item__content {
  padding: 1.5rem 2rem;
}
.grid_item__content_add {
  padding: 2rem 3rem;
  display: flex;
  gap: 3rem;
  align-items: center;
}
.grid_item__content_add svg {
  flex: 0 0 4rem;
}
.grid_item__border {
  position: absolute;
  width: 5px;
  height: 100%;
}

.grid_item:hover {
  text-decoration: none;
  transform: scale(1.1, 1.1);
}
.grid_item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.grid_item__title h3 {
  margin: 0;
  width: 50%;
}
.grid_item__title svg {
  height: 24px;
  width: auto;
}
.grid_item__body {
  font-size: 1.1rem;
  line-height: 1.5rem;
}
.grid_item__italic {
  font-style: italic;
  color: #aaa;
}

.content_wrapper {
  display: flex;
  gap: 2rem;
}
.content_wrapper_image {
  width: 100%;
  height: auto;
}
@media screen and (max-width: 992px) {
  .content_wrapper {
    display: block;
  }
  .content_wrapper_image {
    max-width: 480px;
  }
  .grid_item__content_add {
    display: block;
  }
  .grid_item__content_add svg {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 1280px) {
  .container h2 {
    margin: 2rem 0 0 0;
  }
}
@media screen and (max-width: 992px) {
  .container h2 {
    line-height: 2.8rem;
    font-size: 2rem;
  }
  .banner__wrapper {
    width: 100%;
  }
  .grid__item {
    flex: 1 1 50%;
  }
  .grid_item__wrapper {
    flex: 1 1 100%;
  }
}
@media screen and (max-width: 768px) {
  .container h2 {
    text-align: left;
  }
  .container p {
    text-align: left;
    margin-bottom: 1rem;
  }
  .banner {
    flex-direction: column;
    padding: 1rem;
  }
  .banner > * {
    margin: 1rem 0;
  }
  .banner__text {
    padding: 0;
  }
  .grid__item {
    flex: 1 1 100%;
    margin-bottom: 0;
    padding: 1rem 0;
  }
}
