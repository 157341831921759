.home_one__section_main {
  width: 100%;
  display: flex;
  justify-content: center;
}
.home_one__wrapper {
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  align-items: center;
  padding: 1rem 2rem;
}
.home_one__wrapper h2 {
  font-size: 3rem;
  line-height: 4.2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}
.home_one__wrapper p {
  text-align: center;
  font-size: 1.2rem;
  line-height: 2rem;
  margin-bottom: 4rem;
}
.home_one__grid {
  display: flex;
  width: 100%;
}
.home_one__column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.home_one__column__center {
  margin: 0 1rem;
}
.home_one__block {
  background-color: var(--raised-background-color);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  flex: 1;
  border: 1px solid var(--background-color);
  flex: 0 0 292px;
}
.home_one__block svg {
  max-width: 100%;
  margin-bottom: 1.5rem;
}
.home_one__block_bar {
  display: flex;
  height: 44px;
  background-color: var(--raised-background-color);
  align-items: center;
  padding: 0 1rem;
}
.home_one__block_bar > div {
  height: 13px;
  width: 13px;
  border-radius: 13px;
  background-color: #51546f;
  margin-right: 1rem;
}
.home_one__block_bar > div:first-child {
  background-color: #7b7e8e;
}
.home_one__label {
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 6px;
}
.home_one__section {
  font-size: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.home_one__section > div {
  margin-bottom: 1rem;
}
.home_one__section__center {
  align-items: center;
  text-align: center;
}
@media screen and (max-width: 1280px) {
  .home_one__wrapper h2 {
    margin: 2rem 0 0 0;
  }
}
@media screen and (max-width: 992px) {
  .home_one__grid {
    flex-wrap: wrap;
  }
  .home_one__column {
    flex: 1 1 50%;
  }
  .home_one__column:first-child {
    padding-right: 0.5rem;
  }
  .home_one__column:nth-child(3) {
    padding-left: 0.5rem;
  }
  .home_one__column__center {
    margin: 0;
    order: 3;
  }
  .home_one__wrapper h2 {
    line-height: 2.8rem;
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .home_one__column {
    flex: 1 1 100%;
  }
  .home_one__column:first-child {
    padding-right: 0;
  }
  .home_one__column:nth-child(3) {
    padding-left: 0;
  }
  .home_one__wrapper h2 {
    text-align: left;
  }
  .home_one__wrapper p {
    text-align: left;
    margin-bottom: 1rem;
  }
  .home_one__block {
    width: 100%;
    flex: auto;
  }
}
